<template>
  <div class="work-report-box">
   <!-- <headers></headers> -->
    <div class="breadcrumb-box">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/item-list' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item><a href="/">作业主题</a></el-breadcrumb-item>
        <el-breadcrumb-item>作业详情</el-breadcrumb-item>
        <el-breadcrumb-item>作业报告</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="cont-box">
      <div class="title-box">
        <div class="title">作业报告</div>
        <div class="doct"></div>
      </div>
      <div class="survey-box">
        <div class="survey-text">
          整体概况
        </div>
        <div>
          <img src="../../assets/lianghao.png" alt="" />
        </div>
      </div>
      <div class="summary">
        本次测评考察的学习内容是
        <span class="important">课堂小作业一</span> ，你在该内容上的学习处于
        <span class="important">良好</span>
        水平。表现不错，还有进度空间，继续努力哦！在“
        <span class="important">课堂小作业一</span> ”这一学习内容上，你的
        <span class="important">课程资源开发、利用能力</span>
        需要重点提高，你可以根据自己的答题情况，加强相关内容的学习哦。
      </div>
      <div class="ability-performance">
        能力表现
      </div>
      <div
        id="myChart-Performance"
        :style="{ width: '900px', height: '459px', margin: '36px auto 0' }"
      ></div>
      <div class="ability-performance">
        详细能力
      </div>
      <div
        class="data-box"
        v-for="(ability, index) in abilityList"
        :key="index"
      >
        <div class="title">{{ ability.title }}（{{ ability.score }}分）</div>
        <div>
          <el-table
            :data="ability.children"
            :span-method="objectSpanMethod"
            :header-cell-style="headerCellStyle"
            :cell-style="cellStyle"
            border
            style="width: 100%; margin-top: 20px"
          >
            <el-table-column
              prop="secondaryCapability"
              label="二级能力"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="thirdAbility"
              label="三级能力"
              width="260"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="thirdScore"
              label="三级能力得分"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="thirdAverage"
              label="三级能力平均分"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="thirdTotal"
              label="三级能力总分"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="correspondingNum"
              label="对应题号"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import { myChartPerformanceOption } from "@/common/options";
export default {
  data() {
    return {
      spanArr: [], //存放每一行记录的合并数
      position: 0, // spanArr 的索引
      abilityList: [
        {
          title: "教学设计能力",
          score: "7",
          children: [
            {
              secondaryCapability: "学情分析能力(5分）",
              thirdAbility: "分析学生认知",
              thirdScore: "2",
              thirdAverage: "2.5",
              thirdTotal: "5",
              correspondingNum: "1,2"
            },
            {
              secondaryCapability: "学情分析能力(5分）",
              thirdAbility: "预测学生问题",
              thirdScore: "3",
              thirdAverage: "2",
              thirdTotal: "5",
              correspondingNum: "2"
            },
            {
              secondaryCapability: "教学过程设计能力(2分）",
              thirdAbility: "问题设计有吸引力",
              thirdScore: "2",
              thirdAverage: "2",
              thirdTotal: "5",
              correspondingNum: "1,3"
            }
          ]
        },
        {
          title: "课程资源开发、利用能力",
          score: "3",
          children: [
            {
              secondaryCapability: "课程资源开发能力(1.5分）",
              thirdAbility: "合理运用信息技术",
              thirdScore: "1.5",
              thirdAverage: "2.5",
              thirdTotal: "5",
              correspondingNum: "2"
            },
            {
              secondaryCapability: "课程资源利用能力(1.5分）",
              thirdAbility: "资源有效用于实践",
              thirdScore: "1.5",
              thirdAverage: "3",
              thirdTotal: "5",
              correspondingNum: "5"
            }
          ]
        },
        {
          title: "教学示范能力",
          score: "7",
          children: [
            {
              secondaryCapability: "口语表达能力(7分）",
              thirdAbility: "科学性与艺术性",
              thirdScore: "3",
              thirdAverage: "2",
              thirdTotal: "5",
              correspondingNum: "3"
            },
            {
              secondaryCapability: "口语表达能力(7分）",
              thirdAbility: "可接受性与启发性",
              thirdScore: "4",
              thirdAverage: "3",
              thirdTotal: "5",
              correspondingNum: "2,3"
            }
          ]
        },
        {
          title: "教学管理能力",
          score: "2",
          children: [
            {
              secondaryCapability: "课堂调控能力(2分）",
              thirdAbility: "管理课堂秩序",
              thirdScore: "2",
              thirdAverage: "3",
              thirdTotal: "5",
              correspondingNum: "3"
            }
          ]
        },
        {
          title: "教学研究创新能力",
          score: "1.5",
          children: [
            {
              secondaryCapability: "创新能力(1.5分）",
              thirdAbility: "教学设计创新",
              thirdScore: "1.5",
              thirdAverage: "2",
              thirdTotal: "5",
              correspondingNum: "4"
            }
          ]
        }
      ]
    };
  },
  methods: {
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "color:#FFFFFF;background:#2373EC";
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F9F9F9";
    },
    GetSpanArr() {
      this.abilityList.forEach(data => {
        data.spanArr = [];
        data.position = 0;
        data.children.forEach((element, index) => {
          //遍历数据 得到每项 和下标
          if (index === 0) {
            //如果是一条数据 直接不合并
            data.spanArr.push(1); //记录合并数
            data.position = 0; //记录spanArr1的索引值
          } else {
            if (
              element.secondaryCapability ===
              data.children[index - 1].secondaryCapability
            ) {
              //非第一条数据 则判断是否与上一条相邻的数据值相等
              data.spanArr[data.position] += 1; //相等则说明需要合并 rousoan:2 = 合并一行
              data.spanArr.push(0); //记录索引
            } else {
              data.spanArr.push(1); //与上一单元格数据不相等 则不合并 rowspan:1 = 不合并
              data.position = index; //记录索引
            }
          }
        });
      });
      this.abilityList.forEach(element => {
        for (const key in element.children) {
          element.children[key].spanArr = element.spanArr[key];
        }
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        // console.log("row", row);
        // console.log("column", column);
        // console.log("rowIndex", rowIndex);
        // console.log("columnIndex", columnIndex);

        const _row = row.spanArr;
        const _col = _row > 0 ? 1 : 0; //1为不合并 0为合并至最后一列
        return {
          //返回需要合并的对象
          rowspan: _row,
          colspan: _col
        };
      }
    },
    drawLine() {
      // 能力表现
      let myChartPerformance = this.$echarts.init(
        document.getElementById("myChart-Performance")
      );
      myChartPerformance.setOption(myChartPerformanceOption);
    }
  },
  mounted() {
    this.drawLine();
    this.GetSpanArr();
  }
};
</script>

<style lang="less" scoped>
/*媒体查询*/
/*当页面大于1200px 时，大屏幕，主要是PC 端*/
@media (min-width: 1200px) {
  .breadcrumb-box {
    width: 1200px;
    margin: 0 auto 32px;
    padding-top: 52px;
  }
  .cont-box {
    width: 1200px;
  }
  .summary {
    width: 1072px;
  }
  .data-box {
    width: 1141px;
    margin-left: 44px;
    .title {
      margin-top: 25px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
    }
  }
  .survey-text {
    margin-top: 30px;
    margin-left: 43px;
    margin-right: 301px;
    font-size: 20px;
    font-weight: bold;
  }
}

/*在992 和1199 像素之间的屏幕里，中等屏幕，分辨率低的PC*/
@media (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-box {
    width: 900px;
    margin: 0 auto 32px;
    padding-top: 52px;
  }
  .cont-box {
    width: 900px;
  }
  .summary {
    width: 772px;
  }
  .data-box {
    width: 811px;
    margin-left: 44px;
    .title {
      margin-top: 25px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
    }
  }
  .survey-text {
    margin-top: 30px;
    margin-left: 43px;
    margin-right: 151px;
    font-size: 20px;
    font-weight: bold;
  }
}

.work-report-box {
  background: #fafafa;
  .cont-box {
    margin: 0 auto 248px;
    background: #ffffff;
    padding-bottom: 69px;
    box-shadow: 0px 5px 29px 0px rgba(22, 115, 255, 0.05);
    border-radius: 10px;
    .title-box {
      border-bottom: 1px solid #f2f2f2;
      .title {
        padding-top: 29px;
        height: 22px;
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #2373ec;
        line-height: 14px;
        text-align: center;
      }
      .doct {
        width: 153px;
        height: 5px;
        background: #2373ec;
        border-radius: 3px;
        margin: 23px auto 0;
      }
    }
    .survey-box {
      display: flex;
    }
    .summary {
      box-sizing: border-box;
      background: rgba(35, 115, 236, 0.03);
      margin: 0 auto;
      padding: 16px 63px 15px 70px;
      line-height: 36px;
      .important {
        color: #2373ec;
      }
    }
    .ability-performance {
      margin-left: 44px;
      margin-top: 52px;
      color: #333333;
      font-size: 20px;
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 24px;
      font-weight: bold;
    }
  }
}
</style>